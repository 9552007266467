import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The colors in our palette come from our brand and the authenticity of craftsmanship from our design team. We always enhance the user experience from the brand, marketing to the product into a single unified within the Tunggal Design Language System.`}</p>
    </PageDescription>
    <h2>{`Specifications`}</h2>
    <p>{`Each of the 10 color families have been divided into 10 swatches ranging from light to dark. RGB and HEX values are provided for digital appliations along with Pantone and CMYK values for print.`}</p>
    <h3>{`Brand Color`}</h3>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Navy</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "120.13888888888889%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAAsSAAALEgHS3X78AAADV0lEQVQ4y52SW08bRwCFR6IF2+D1+r67ETH4uvYaAzEEX0jwZY1tWhIMyJQkJpQ7NFWlqBBQWqlSQvKQS6OmCVX72L60qtR/xc+Y05kJSUNVtXYfPp3dnXO+3Ycl0bWTU33zRwRa39Mz0C4DK6+gr/9AOYmtnxD59OSUZO/+gvpXv1J+2AkBRmj1BB99/Ruuf/M7msd/0Ozdn0HU5jPqW3yK/uVv6RnoBP/iU76n3oUn4C4Su/mMZnZeQ20cU2XuGJ0yuv4d2J5md1/DWHlOid56juzeCbT5x1Aaj5i4A+YfMeFLjG+/Eo7k7RcgzsoR7MV78FTvw9sJtftiI5UPYS/dg1Q6hGwegRjNB6jeeQlP+QDO4pdwlfbbg3W95gGyq09Q3H4hSDYfgvCHWu0I7nZF7+Eu78M/fQiV7Xl6Kwcg7is78BX2IGU3/gebYuu++sbBXUS/tg9z8zHk/JYoOHLnkd7m32T2zIbYFNaPcWX1AabWHiJ+nX1h9/ANdA0toTd9C93Dy+gZufGO9+9t7LxvrHWO3nQLXcklfJhaFsl75EJ+BUZ9F47RpshweQODxTUMFtYQNTdFhkrr4rwnMQer0YBFMAdbch6J2i5jB0m2VTK3QDQh3IPj0pJIvbqDOCtFK9tIfXxHZII9d4+zL000YGUSC8eYhy21KM44xsxnUHItEEu4gg8GSuiNVtEdNNETqrzjr3tTnNv1Ovr0mkBcx2piy3tdgRK4iySnllFZ+hzOmAlHpAw5av4jjmhZnL9FCpfg0iuYauxi8toWqp98gVi+yX6b+DT8Q/U3pcj50b8huuxFfOtJVKGkZsBdxBPKwh+dhHPgMuROCVyGL5Jn+7xwcBeJjddRnL0NVzAjClzcDvJZpgsLmKzdRHVhA8ncLEivkoTFG4fcPwJn/yjL9uF9m9+AzZeAldGnDoGEjQzSuWm4VB2yEoVTibH8b5xqDJIvAr4fmTCFIxifYMJkjo7la3BpcSorOiu2DxcHExkYlwo0zRxhI0uJzaHR7j4/JM9F6vAEGBfRPgFYJZVaGD12BVbmIkZqHGZ1ljrcGuyyAsmpwt4urD82cRVFcwbZyRKN6MPst1EGTtX+MCSXRu1OjbJEJ/i0IPVfCFGfNgiPEjj9ExkFGMi/s+M6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Bhinneka Deep Sea",
              "title": "Bhinneka Deep Sea",
              "src": "/static/29bc315aecfb34987ea74603a9632c60/7e1e1/NAVY.png",
              "srcSet": ["/static/29bc315aecfb34987ea74603a9632c60/7fc1e/NAVY.png 288w", "/static/29bc315aecfb34987ea74603a9632c60/7e1e1/NAVY.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Yellow</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "13.194444444444445%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsSAAALEgHS3X78AAAAjklEQVQI121PWwrCQBArOOze/wKiqAjeQfDDWrc+ETyH0C9duxMz3QoV/AjJhkxmtkhB0rsSJZD2Ag2ZW77bnpnpfNSjjof6VQriTtQyzMai2QiuC4fz3OEw9bhQ35YO9cSjGnvcV9l/rFly/C1kEQJzNn+aObXZQgcXPrdEmbcaTH99u/rfhf1P1MqJ+AEbuqJF0lgSjwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Bhinneka Golden Sun",
              "title": "Bhinneka Golden Sun",
              "src": "/static/85860ec4ff767b8cf4cb75d28864342f/7e1e1/GOLD.png",
              "srcSet": ["/static/85860ec4ff767b8cf4cb75d28864342f/7fc1e/GOLD.png 288w", "/static/85860ec4ff767b8cf4cb75d28864342f/7e1e1/GOLD.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Red</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "13.194444444444445%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsSAAALEgHS3X78AAAAmUlEQVQI122OOw/CIBSF+R0+a+sDdXE2LsZBrUptdXBw8f+XFtYmcrwXmhgThy8c4HD5hO6N33o4cwR0fwLaQw+m35XP+I5zS9lNPL4TScZVodOISq5gDhlMeoW53EI+EucC5kTsFerNDkbdYZ8v1Ostyk4cBtIQWzyol9M75WyaQ/wYxgtiHn7lPGozW3JOln8MvVkwjGTzAeqviOBCUbg/AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Bhinneka Neutical Twilight",
              "title": "Bhinneka Neutical Twilight",
              "src": "/static/5748533b87940a867af0784e26175dad/7e1e1/RED.png",
              "srcSet": ["/static/5748533b87940a867af0784e26175dad/7fc1e/RED.png 288w", "/static/5748533b87940a867af0784e26175dad/7e1e1/RED.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
    </Row>
    <h3>{`Contextual Color`}</h3>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Slate</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "160.06944444444443%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAACXBIWXMAAAsSAAALEgHS3X78AAAEwklEQVRIx52SaUyUVxSG7/9qBA1kmBEUEpv4w9QfXZR1xmJBERER1waaglFssdYlRltXLCAoltZi1S6IsijSzVAFRjbFgoAsCjOMgKwDOODGCFLKfXvPFWmMOg69yZMz37nv+97zfXfY0NCQEWKNjIzw/v5+PjAwAMJsNmNwcBCccwFG6/PQElrpI+2TJ0NG1msyQYTy4eFhVFRUoLa2FjqdDuXl5dDr9WPGF9fTvsFgQE1NtaCG3+vrA6Md2hr+Z4SPqUaXGJr6Fhn1PPNx1tpu5G0dXeh//ITfrNND13AbekMjmu+0oX9gCObXoDc04VZ9A9c1NMLYbeKsvbMbHQLapLB6nUEI9KjTNeDx4N8WEUOgsbkFt5ta0CQG6OzqAXtkHgRx/6H5ObFZQD1LPHj0eGxSCqccRq95taQUffcfwdT34Dl67z18JaS/23sfxVeuQXu5APkFxdCL12Y9pnvovttn0WyJrp5edHWbZAYNwVraOsV3aAVdDH3P8UAe8tIF0re809oBdqOqFtkXcyBuGx3GHqvDSNva3omcXC0u5eThjwvZuF5+A/S3EbdjkunN46GlAy1tT73tnT2ytnV0g5VVVCNXW4g6fSPqG5qsRmdoRvVNHfLyi1BQXIKcvAL8db2SAqtkIIn0t+9IoTWQtvpmPfILr6Kw+Br+zNGitLwKrLbOIDZbcKOmDpXV4+GWnLBehNc3NMsMymLZl7RIOZMhxq1CWUWNOKXaKq5X1qLoahnSz2YhLeM8UtMzcTE3H4yaudoiXCurQulYYI084HWUlFZCW3AFOcKfe7kIRSVlYGfO/orTGVlIPfcbklMzcUqQnHoO1E/L/N0i5ElJzxL+X5CSdl56WNLJZMTEH5HNhG+OSQ4eTsRPp88iI+vCK8PSxd6ptEzEJXyL+CNHERUTj6QTP4N9dyIZR48nI+nkKXz/4+kxjv2QInvWQBkE/WYHYhNwKDEJO/d8hR27D8i6c8+zapkv9kYj9lCinPKwyNgXHQ8WE5+Ir48ex679sePiy32x2B11EHHidWkgyogSw7H1kVuwdsMmRGzc+r8gLxEesQnrPt0MFrl5O/ZExSA0fANCwiLGxUdrP8HeA7HYHx2H6LgEbNm+C4yaYesiZWCoEMlqBSGj2vD1GxEmWBvxGT4WOWzRkuUICFqFBf5B8PVfKqs1+C56qiUvsXjpSixcvAwsNGw9Nm/bKR+eiai+Dh+/QPgFBCNy0zZ8vnWHZHVIGJjnPB+4enlD7b0QHhof0LNmvp+VLISrp7fEXT0fXu8vAJvv64/A4FWY6zEP/oHBWLAoEO+6euHtOR54Z66nwOMleMp90gUsXYEly1aKulwcIAJ9/AL4spUfws3LmwctXyM3KXyOu0bynhuhfilzhC4weDWCVqzhAsz7wI8zpcqR2yuUcJw2ndvZKwioHKdB9OGgmgpHp+lwmu4icH4pCgcVVyinEpjqOI2zWbNmwdfXhzsoFNBoNNCo1Zg9+y14uLvDzc0VKpUSEydOhI3NJEya9B82NjaYMOENzJw5U2rVajV/c8YMMAcHB6OTkxOmTJnCXVxcuLOzM0QP1CPs7OxgazsZkye/iK2tLRQKBVcqlVylUsHe3t74L6OZMe/nQ99sAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "slate",
              "title": "slate",
              "src": "/static/09fe1c2e0325f35ade88c78e5267e216/7e1e1/SLATE.png",
              "srcSet": ["/static/09fe1c2e0325f35ade88c78e5267e216/7fc1e/SLATE.png 288w", "/static/09fe1c2e0325f35ade88c78e5267e216/7e1e1/SLATE.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Info</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.33333333333331%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAAD00lEQVRIx53RfUzUdRwH8O+slYCwSQpbG3J3BsmjqKPZCBoBdzwYrLXECRTIwxBpqwlNeZhcynEPMDOHE9gKswcrTQIKEjj0BMwJHsfdIZcIAR0HKB5PqQH3fff9Ha0/83f98drn+9vv/f7st++PjM08Hp20rsIyZ7OxSbkzX6yD8ZnHGLEs0t+nH4FZIv13JtBnGKX3lwDLPyFeWHZmEejpG4K6S4t2TS/t7r0DYp6DzcJejM+u0vGHduBtln3pAjC1BMpwZ0puDltph3YMessq1VtWwNvkCgxTNlzVm3Hl1ght6xvFdeMUJbd+e4DWHiOGZlYxNO2IFZju29DRexfNGh1+uj4AjW4MRDPG7mESUN9bgXp42WHdZuCGZW1H1wRAajonUPmDFl/d/hPf6pdxYeAvnpbxTf8TnGoetPerLvfjdIsJ5OveOZzrnsJF/RNcMi7jkoEnluU6X9yYZn2LfceXNx+AyNsXoGAq2ub/F0XHIrgd8vZ5yNkzOXx+ELmnr6K0cQbSlnmU/Wxl5niwQtq6gA/rB5Bzsg15Z7qQX/MrSHHjLIqbHqK4kTPrOK7bZEVJM8Mm2V9tQvInOqTWjCKtbgxptQ6oG8e+Tw1IPqm17+DOJOWUFu/IOpBRN4Ks+j+Q+dkEDvCU+bkZ+yp7WF+NvRWdSFZ2gUhUdyFWDSNWZUK0fAgSpQlxlYyKHwnrilX37Ds4JEKqQ7xCj11Ft9k0ILRYC7/DvQgo7ENAwX/jcpHHdYiT65HAdkSfGAAJ/1gPsdyI0FIdYhWDCJPqEXy0HyFFT7ed5SLLDYiSGRFTYUTkCXaHooxWCNJb8NKBtSli0yfzF96E6VxvrcudSfgHV/DWsWt4Ob0JgpQGiFJ/hJAHLue9vwGxR9RIKr2Gt8s0iC/qBNma2gCfd1ko5bLDBAzX9Ulr+HcSr/izECXWwjOm2mEe0dXg+sI3a7E1qc4+SeDeesTkfY8XY8/CI+YMPMX8bWYLd6WeR0T2BUjyL+KN3O9A3F6VwzlUBvfXlNgUrsIL4UreNkWo4Lq7gvXL4fyKDC4MEb5ehu0JcmzceQQbggrgGlzIm0tgAXyjjiNkjwIBsTL4i8vZT4kqpyGJVXAPLaGuIUfhtqOItw0sv00ix46kKhqYoIR/nIKSZ4U5lHhlwcnnIHXxzaPOvnngi+XxjCCH/dksum5LNtZ5Z1PiG1aIsEQpdfPJxvNb0uEkyMB676fjcs95vQf/iI+4PnYnHKM7xSUgG/1yzZ7B78NZlEXXCzKpkzATfLE83P0OwiPoEPUIysfmwEOP/gYcR6lQqIcATQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "info",
              "title": "info",
              "src": "/static/2a2d5e44b1006959bbd5d7e914b62618/7e1e1/INFO.png",
              "srcSet": ["/static/2a2d5e44b1006959bbd5d7e914b62618/7fc1e/INFO.png 288w", "/static/2a2d5e44b1006959bbd5d7e914b62618/7e1e1/INFO.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Success</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.33333333333331%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAAD2ElEQVRIx5XQb1DTdRwH8O+TLj3yuhTR07rU6y4cskEbDOTPxjYFNEt6YjhANv4aNkQsIujQ7EFDE4s/mQKDyCdiRIkTD1BYIlMZKv+MeUYngYAbJnGICN93399vPOkJ/vbgde/Pb3t/Pr/byPjU8ODj2XFMzjvnn8w5KAOh2A4eTY+A3aBcMlPEPtSPu3/10Gk8weScky8JwrpTeAxb/3Vc67Kg1dpMO3utIJNwzE+zLybmRukCuONfcIcnKMPNlPQ5Omm7vQVDzwfog9kBuOPvOTtsQ+24MWihNwbbYHtwlRK7sxvWgTY8fP4nRnj3F3IxrDN7H6Pzg2y3Fb/3NPHa+y+D9NObsOMWup91uO3OTAe/ew+3F9wCqRusQcX1ErRM/grLjBltTxuYCwI0wPLMjDO9p1FlK2M3ilHd9T3IlX/Oo8lRj6tPG9E+c8ltl8bqcHHkHMzDtXwSk6MUleMl4NLkLHWlUKxfNVGGKmcZqrlkiLH7EPKbs1E6fBQ/OIpwcvw4Tj4SgPVOOYtwuD0XnzZkIse8HwWWHJDisaMocRxD8WghvnvIjLrhf30jiscKQfZ2piPZkox9vftguGtgPnaDAek305HWkYrUa6lIs6aBZFj3IrlRj6y+TGTbs5D9BzOQ5ZoXwzoH7x1A2pUU6M06JNYnIKUpCST+jg57+pIQ15WIuNscHeJsidB27hEkoUfP73MSevUgwWfVCKxQIOxCJOTVEZBXKRF2MQqKlm1QNL/A5W0IOqNCYKUCgSYlPxNV43Yof4uGumUHIhqiEXHeNW9pew+a1sVxHWV9FMJrt0LxcyQ/kwD2EHBuK2RnWbIPObJa17MQAXVs55cFbCY+J8Kw8ctgiE0qSGrUkPyoduWLcL2fNBB9vRkbjwSzDIHIGALia4qAT6US4mq127ijm8qVEJ0Oh+hUOHzKFSBvf6OC74lIbDCGYj17w3o+hVnH+qLjGoi/jeSJijTsPyzdgXer9XjrmALrCtnBwlBBNjBvGjdDVR7L7++sSYGmQguyKl+GFZ/7Y21BEN4oCMbrbuD6/H6uH39jZZ6U/eQcDWQF72O1QY7lGe/AM0OKFQJwveUf+cP7Mw3khz/gb/jmRYOI8rdQ+VcxWHMgiHpmSuGVKcNKAbz2y8D1vfM0CDyyk7/hd2g7JUsTfOhLWm8sS5TQV3X+jB+E88eSeB+wff7Gy3EiSrwNUQjLi6WvxUux9ENfeMSKhdktwZJdmyA9GIOQ3F1QfKGl0k9iQDx1gcNrUkKwLM6PemjF9BWtBEJ57BZjVVIQVicF07WpofDSy6f/A8cXiCof2lnuAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "success",
              "title": "success",
              "src": "/static/228a1c4b07f27d94a75d23bc9c128120/7e1e1/SUCCESS.png",
              "srcSet": ["/static/228a1c4b07f27d94a75d23bc9c128120/7fc1e/SUCCESS.png 288w", "/static/228a1c4b07f27d94a75d23bc9c128120/7e1e1/SUCCESS.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Warning</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.33333333333331%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAADsElEQVRIx53SWUxcZRjG8Q+YGQiY6JVeGWJiG6XXxltkqVpqookNXbxQawPSEmmhVlsLhnpjVWgrEyAsasUN07IqS6mUilILlAKFYS0gw1qhijCyyPv3O2emqE1kBi9+eb6c87xvvpMZtTQ/NMSSE5bHVmXJKWKcvRAPPYOeZ+HXAVlxjeCaG5xXzuE2RgabBZnSpTFkeVwX12d0jC6rUwz0NNHWXEdXe4N0XPsOxcrIKjKKLN4UFocM+ErP6KU/A04xdrA6KmpuskGc/d/AvL7l/FVEwwu501toZnq4ltG+Sr3jW6ZHakXNjjfiuF4OrnaTLLSvnf+LmL3r8EcHw701DHZX0ddRyZCjWn/y7/p2izUwW47Mlun0le7P6HRV6cXV7h0uvXCy8ySdl1JZHrXDVA4ykQ2TOeuSSU9vKpfBn96ho/44bXVv0d+UjpofzuaXnjOsTuTrQqH+sQt0rk88mC7kt0E7t/uzuOU4Y6ZiMBnTwCFEw0eylskmbqaYe9To5RdpKYll8UY89O9HehOgb31Gx+zpflfVbnO+5XwsraWx+oaOfdCj6RSHO72Rf6Yx22ucXzHPyvV9BDN14fx5JRKuRiEaXsidXnMUty+Gc+tCOLMX3dREbTQdxU+weOVpaI1htXkb0uJGqyfvsvb+WgwDZZE4zkeYO7rPRehPbtwCTVpDGFzWGj1ZH4bU/513W3v/g2fe8OMW/T/82EKP3crCVxachVb6s60Yz6TEO8osjORZ6M6y4rC7Z9VccTATn4awUqrzbAhDBSHMfB4CFcFQ6d3sF8FMFYUwrmeNVON5NqbybThzbUzqnC6wMaGfjeb6IMfdNeaMHUaqS2mBnEsJ4sb7NvpOWenJsNKb6ZuB01aq3gzky6QgSvSOiiNBqK4PrHS+Z8OR4V62UcZFjHkjDarikIXSJAtVKf9HAGVJ7vmS1yyUH7Sgvk60kbs30CzUHLZQfdid3hi9C68HULAvkHwP46yK4xXFcdqr/hQnBGxcnPoXlbP3fgoPPMzpnTYyn1ec2uFP5g4/r8ye7ufHh+r5TXyUuJm8uAdRhfEPSNHBzWS/YJOsXQr7bn/su3yge1k7FWcTQylK2iSfJT/CJwdCRaU9qSQ1SpG+TUl6jJ+BjUjbqkiNVnJc79BnUSf3hPJhwmNy7KlAjkQo3oj005R3UX5m/909D5HxchhZ+x+XjJceRaVtDx478ey9HNvqL0ejlByN9sN3+lYxwbz9zD1y4rn7SNse4voLAQXyEdn/KqIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "warning",
              "title": "warning",
              "src": "/static/8913f31d8d37b03cb91e3afd2861ca38/7e1e1/WARNING.png",
              "srcSet": ["/static/8913f31d8d37b03cb91e3afd2861ca38/7fc1e/WARNING.png 288w", "/static/8913f31d8d37b03cb91e3afd2861ca38/7e1e1/WARNING.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
  <Column colMd={4} colLg={4} mdxType="Column">
  <strong>Danger</strong>
        <p>{`  `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "360px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.33333333333331%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAADuUlEQVRIx53RW0yTZxwG8Hcn0Q0TmC5LOI+B0CMUG2ncAFsocnBxbszKhhotyowXW9AAm2ZunOY2XQyOkF0Q48zm3CwxC3WiZYBDRVxLLZQWdqiACCpCiWzqpO+z/1fcNV+9+OX/XjzPk3z52L1bQ57ZqRvwecd8dLnwFos6oD7ujv3Fhfv3uGeGjbjt8DitHDO3MUsBn3dcFCGLu7cw2NOFKx1nYets5S7rRTBMXvdhegyzE8P8EYh2ewjwjoL6XNiA9wZnU31dfKjLglmPgz/0OCDeVfiu9WLc1oHhy62cYMzawdmEsxvO8z9j9rqbuER7OOKCb9QNT3c7Bi6cg7uzBb9fstAn29sBZyf+/a2VWAKG3vPUvwD0ddL7V7Dh7xtg+/pzzJiP4UHLcdw/8504LXO3/8ghdByqRG/jQQx8Uwc2dfpbjJ06gnuWH/Cg9ceA3KfORPMxjJga/Rs3fzoKNl1fDYH3q6rH9v+GgDk/eg9tpcW4+VkFvAf3YvLAHkyJIOSmv9yLi2UlsLxvRHvpNlg/2Ak2UVOGO2Si9jFRd5LunVrhloO5it5CT+Eb+HPTeng2GwJyjfS98yasG9b5N+yEWWnIsuF1/GF8GyPbN2JoexGGtokj5C8VFeCsYS3aCtehnTC3PhODWTq4srPgzNSiX3iv1ovSn6PHAOUH9Tr/hvBmNk0arurzcEWegp6VGbBp0tEdJxXlcmwi7KuyYdetpo18ujlg9tRX0KvNQk+yGo6MTDjStLBKleJIFHCk6/y9Xq3ef5k5UobmMAnMUQqYw6UwR8hwOlopTowSzUKHNIdL/G92RpOLtteKcCpWDVO4Ek2RyWiKSJpfZBJMYXK0vLoGrTkG/JJXiHM6+sumKBWaXlqOkxQ4GaEMmCkmBaZo1dylLVYfthwN0amoW6pE3RLF3F2qEGEuXx+upr4GDZEr0BCVCtYoWYUTOgPqX0zGYQocpqBYdaEyHE3JwYlMA45nFPixmuBEVC2Mw6chcuwPVQSs+tkEVAbF0UY8qhctA9sXk4ZaZS7KQpKw6zkpdgXLiUwEOUopX7lMh/2qNaiR5+CTeC1YVWw6/yIpDx+GJvNyClYslqN8HhWPlC+WoYZGDqjyaSMXtQk6zoqfiudbWSxKgiT83UVSAQJR/GQcqM+NtGF84mXOdsdl4eOV67kxOAmbnknE5gVSIplfkBQbn07A7oRs7NMUYE/KWl6hyAcrCVGN7nxhBbYulPEtCyR8CwVFo2HqY8cSNd/xvFq4//wHfLtn3Nla+kMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "danger",
              "title": "danger",
              "src": "/static/4fd963d14b95824b12184cb3fcbeea26/7e1e1/DANGER.png",
              "srcSet": ["/static/4fd963d14b95824b12184cb3fcbeea26/7fc1e/DANGER.png 288w", "/static/4fd963d14b95824b12184cb3fcbeea26/7e1e1/DANGER.png 360w"],
              "sizes": "(max-width: 360px) 100vw, 360px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      